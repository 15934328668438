import React from "react"
import Layout from "../components/layout"
// import Image from "../components/image"

export default () => (
    <Layout>
      <h1>About</h1>
      <br></br>
      <h4>What is MacroBuddy?</h4>
      <p>MacroBuddy recommends recipes based on your specific macronutrients and diet type.</p>

      <h4>Who is MacroBuddy for?</h4>
      <p>MacroBuddy is ideally for those who track their nutrition daily, but anyone who enjoys cooking and great food can use it.</p>
      <h4>Contact Us</h4>
      <p>You can reach us at <a href="mailto:help@macrobuddy.com" target="_blank"> help@macrobuddy.com </a></p>
    </Layout>
  );